import React from "react"

import "../../styles/modules/resume/resume.module.scss"

var EduPic = require("../images/Edu/Syracuse UniversityB.png")

const ResumeEdu = () => {
  return (
    <section class="mx-5 my-5 grid grid-cols-4 border bg-gray-100">
      <div class="pl-2">
        <img
          class="object-scale-down h-32 m-0"
          src={EduPic}
          alt="Syracuse Univ"
        />
      </div>
      <div class="col-span-3 my-5">
        <h2 class="font-bold">Bachelor of Arts - Computer Graphics</h2>
        <p class=" my-2">Syracuse University</p>
      </div>
    </section>
  )
}

export default ResumeEdu
