import React from "react"

import "../../styles/modules/resume/resume.module.scss"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

library.add(faGraduationCap)

const ResumeSec = () => {
  return (
    <section class="mx-5 my-0 lg:mt-4 xl:mt-4">
      <div class=" text-2xl ">
        <p class="mt-0 ">
          <FontAwesomeIcon icon={faGraduationCap} size="" />
          Education
        </p>
      </div>
      <svg class="" viewBox="0 0 40 01" xmlns="http://www.w3.org/2000/svg">
        <line
          x1="0"
          y1=""
          x2="15"
          y2=""
          stroke="black"
          stroke-width="0.5"
          stroke-linecap="round"
        />
      </svg>
    </section>
  )
}

export default ResumeSec
