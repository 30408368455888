import React from "react"

import "../../styles/modules/resume/resume.module.scss"

var WrkPic = require("../images/work/magic_leap.png")

const ResumeWork = () => {
  return (
    <section class="mx-5 my-0 grid grid-cols-4 border bg-gray-100">
      <div class="">
        <img class="object-scale-down h-32 m-0" src={WrkPic} alt="Magic Leap" />
      </div>
      <div class="col-span-3 ml-4 my-2">
        <h2 class="font-bold">
          QA Engineer (Genesis Global Contractor) - Magic Leap
        </h2>
        <p class=" my-2 text-sm">May 2017 to Present</p>
        <p class="leading-tight">
          Works with the Embedded Algorithms Engineering group to QA all aspects
          related to embedded algorithms on Magic Leap devices
        </p>
        <ul class="list-disc leading-none ml-3 mt-2 pl-6 text-sm">
          <li>
            Focus on testing SLAM related features like Head pose,
            relocalization, eye tracking and hand gestures
          </li>
          <li>Develop Test Cases</li>
          <li>Coordinate with other QA groups within the Company</li>
        </ul>
      </div>
    </section>
  )
}

export default ResumeWork
