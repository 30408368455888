import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"
import Resume from "./resume"

const ResumePage = () => {
  return (
    <Layout>
      <Head title="Resume" />
      <Resume />
    </Layout>
  )
}

export default ResumePage
