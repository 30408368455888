import React from "react"

import "../../styles/modules/resume/resume.module.scss"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faFilePdf } from "@fortawesome/free-regular-svg-icons"
import { faMapMarkerAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Resume from "../images/TownsendDavidResume_Current_2O19.pdf"

library.add(faFilePdf, faGithub, faLinkedin, faMapMarkerAlt, faEnvelope)

var David = require("../images/profilePhoto.png")

const ResumeAbout = () => {
  return (
    <section class="mx-5 my-5 flex flex-col bg-yellow-100 border px-1 mt-5">
      <img
        class=" object-scale-down h-24 w-full my-2 "
        src={David}
        alt="David Townsend"
      />
      <div class=" text-center">
        <h2 class="font-bold pb-1">David Townsend</h2>
        <hr />
        <h2 class="font-bold pt-1 pb-2">QA Engineer/Animator</h2>
        <p class="text-sm">
          Imaginative and critical thinker that is passionate about trying to
          improve the world through technology
        </p>
        <hr />
        <div class="flex text-center pt-3 ml-8 xsm:ml-16 sm:ml-40 md:ml-56 lg:ml-1 xl:ml-6">
          <div class="pr-1">
            <FontAwesomeIcon icon={faMapMarkerAlt} size="" />
          </div>
          <p>San Francisco,CA</p>
          <div class="pl-1">
            <a href="dtownsend90@gmail.com">
              <FontAwesomeIcon icon={faEnvelope} size="" />
            </a>
          </div>
        </div>
        <div class="flex pt-1 ml-2">
          <div class="h-8 w-8 xsm:ml-8 sm:ml-32 md:ml-48 lg:ml-0 xl:ml-0">
            <a href={Resume}>
              <FontAwesomeIcon icon={faFilePdf} size="2x" color="red" />
            </a>
          </div>
          <div class="h-8 w-8 xsm:ml-36 sm:ml-36 md:ml-36 lg:ml-20 xl:ml-34">
            <a href="https://www.linkedin.com/in/david-townsend-0999242/">
              <FontAwesomeIcon icon={faLinkedin} size="2x" color="Dodgerblue" />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ResumeAbout
