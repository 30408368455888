import React from "react"

import "../../styles/modules/resume/resume.module.scss"

var WrkPic = require("../images/work/Loyalize_logo.png")

const ResumeWork = () => {
  return (
    <section class="mx-5 my-5 grid grid-cols-4 border bg-gray-100">
      <div class="">
        <img class="object-scale-down h-32 m-0" src={WrkPic} alt="Loyalize" />
      </div>
      <div class="col-span-3 ml-4 my-2">
        <h2 class="font-bold">
            QA Tester - Loyalize.com
        </h2>
        <p class=" my-2 text-sm">Apr 2011 - Jan 2012</p>
        <ul class="list-disc leading-none ml-3 mt-2 pl-6 text-sm">
          <li>
            Provided the first level of customer support to Loyalize.com clients
          </li>
          <li>Wrote and executed test plans on web portals and mobile   apps</li>
          <li>Entered and regressed bugs in Jira</li>
        </ul>
      </div>
    </section>
  )
}

export default ResumeWork