import React from "react"

import "../../styles/modules/resume/resume.module.scss"

var WrkPic = require("../images/work/The Orphanage_logo.png")

const ResumeWork = () => {
  return (
    <section class="mx-5 my-5 grid grid-cols-4 border bg-gray-100">
      <div class="">
        <img class="object-scale-down h-32 m-0" src={WrkPic} alt="The Orphange" />
      </div>
      <div class="col-span-3 ml-4 my-2">
        <h2 class="font-bold">
            Resource Administrator (Render Wrangler) - The Orphanage
        </h2>
        <p class=" my-2 text-sm">Dec 2007 - Feb 2009</p>
        <ul class="list-disc leading-none ml-3 mt-2 pl-6 text-sm">
          <li>
            Performed comprehensive quality checks on dailies
          </li>
          <li>Troubleshoot artist render problems</li>
          <li>Helped with server maintenance</li>
        </ul>
      </div>
    </section>
  )
}

export default ResumeWork