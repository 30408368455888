import React from "react"

import "../../styles/modules/resume/resume.module.scss"

const SkillsEdu = () => {
  return (
    <section class="mx-5 my-0 grid grid-cols-1">
      <div class="">
        <p class="bg-indigo-700 inline-block text-white font-medium py-1 px-1 m-1 rounded-lg">
          HTML
        </p>
        <p class="bg-indigo-700 inline-block text-white font-medium py-1 px-1 m-1 rounded-lg">
          CSS
        </p>
        <p class="bg-indigo-700 inline-block text-white font-medium py-1 px-1 m-1 rounded-lg">
          Javascript
        </p>
        <p class="bg-indigo-700 inline-block text-white font-medium py-1 px-1 m-1 rounded-lg">
          React
        </p>
        <p class="bg-indigo-700 inline-block text-white font-medium py-1 px-1 m-1 rounded-lg">
          Maya
        </p>
        <p class="bg-indigo-700 inline-block text-white font-medium py-1 px-1 m-1 rounded-lg">
          Python
        </p>
        <p class="bg-indigo-700 inline-block text-white font-medium py-1 px-1 m-1 rounded-lg">
          Adobe Suite
        </p>
        <p class="bg-indigo-700 inline-block text-white font-medium py-1 px-1 m-1 rounded-lg">
          Office Suite
        </p>
        <p class="bg-indigo-700 inline-block text-white font-medium py-1 px-1 m-1 rounded-lg">
          Modo
        </p>
        <p class="bg-indigo-700 inline-block text-white font-medium py-1 px-1 m-1 rounded-lg">
          Jira
        </p>
        <p class="bg-indigo-700 inline-block text-white font-medium py-1 px-1 m-1 rounded-lg">
          Unity
        </p>
        <p class="bg-indigo-700 inline-block text-white font-medium py-1 px-1 m-1 rounded-lg">
          UE4
        </p>
        <p class="bg-indigo-700 inline-block text-white font-medium py-1 px-1 m-1 rounded-lg">
          Windows
        </p>
        <p class="bg-indigo-700 inline-block text-white font-medium py-1 px-1 m-1 rounded-lg">
          OSX
        </p>
        <p class="bg-indigo-700 inline-block text-white font-medium py-1 px-1 m-1 rounded-lg">
          Linux
        </p>
      </div>
    </section>
  )
}

export default SkillsEdu
