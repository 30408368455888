import React from "react"

import "../../styles/modules/resume/resume.module.scss"

var EduPic = require("../images/Edu/Academy-of-Art-University_LogoB.png")

const ResumeEdu = () => {
  return (
    <section class="mx-5 my-0 grid grid-cols-4 border bg-gray-100">
      <div class="pl-2">
        <img
          class=" object-contain h-32 w-24 m-0"
          src={EduPic}
          alt="Academy of Art Univ"
        />
      </div>
      <div class="col-span-3 my-5">
        <h2 class="font-bold">Masters (Unfinshed) - Computer Animation</h2>
        <p class=" my-2">Academy of Art University</p>
      </div>
    </section>
  )
}

export default ResumeEdu
