import React from "react"

import "../../styles/modules/resume/resume.module.scss"

var WrkPic = require("../images/work/Viggle_logo.png")

const ResumeWork = () => {
  return (
    <section class="mx-5 my-5 grid grid-cols-4 border bg-gray-100">
      <div class="">
        <img class="object-scale-down h-32 m-0" src={WrkPic} alt="Viggle" />
      </div>
      <div class="col-span-3 ml-4 my-2">
        <h2 class="font-bold">
            QA Engineer - Viggle
        </h2>
        <p class=" my-2 text-sm">Feb 2012 - Jul 2013</p>
        <ul class="list-disc leading-none ml-3 mt-2 pl-6 text-sm">
          <li>
            Interacted with developers on a daily basiss
          </li>
          <li>Wrote test plans for Viggle and MyGuy app</li>
          <li>Performed blackbox testing on Viggle and MyGuy app</li>
        </ul>
      </div>
    </section>
  )
}

export default ResumeWork
