/*Purpuse of this page is to show a clean resume page*/
import React from "react"

import "../styles/index.scss"

import Resume_Syr_Edu from "../resume/components/resume_Syr_edu"
import Resume_Aau_Edu from "../resume/components/resume_Aau_edu"
import ResumeSkills from "../resume/components/resume_skills"
import Resume_Ml_Work from "../resume/components/resume_Ml_work"
import Resume_Oc_Work from "../resume/components/resume_Oc_work"
import Resume_Fb_Work from "../resume/components/resume_Fb_work"
import Resume_Vi_Work from "../resume/components/resume_Vi_work"
import Resume_Lo_Work from "../resume/components/resume_Lo_work"
import Resume_Or_Work from "../resume/components/resume_Or_work"
import Resume_Lu_Work from "../resume/components/resume_Lu_work"
import ResumeAbout from "../resume/components/resume_about"
import Resume_Edu_Sec from "../resume/components/resume_Edu_section"
import Resume_Ski_Sec from "../resume/components/resume_Ski_section"
import Resume_Wrk_Sec from "../resume/components/resume_Wrk_section"

const Resume_Alone = () => {
  return (
    <div class="grid grid-cols-4">
      <div class="pl-4 xsm:col-span-4 sm:col-span-4 md:col-span-4 lg:col-span-1">
        <ResumeAbout />
        <div class="mt-5 xsm: hidden lg:block">
          <Resume_Ski_Sec />
          <ResumeSkills />
        </div>
      </div>
      <div class="xsm:col-span-4 sm:col-span-4 md:col-span-4 lg:col-span-3">
        <div class="mt-5 xsm:block sm:block lg:hidden">
          <Resume_Ski_Sec />
          <ResumeSkills />
        </div>
        <Resume_Wrk_Sec />
        <Resume_Ml_Work />
        <Resume_Oc_Work />
        <Resume_Fb_Work />
        <Resume_Vi_Work />
        <Resume_Lo_Work />
        <Resume_Or_Work />
        <Resume_Lu_Work />
        <Resume_Edu_Sec />
        <Resume_Aau_Edu />
        <Resume_Syr_Edu />
      </div>
    </div>
  )
}

export default Resume_Alone
