import React from "react"

import "../../styles/modules/resume/resume.module.scss"

var WrkPic = require("../images/work/Oculus_logo.png")

const ResumeWork = () => {
  return (
    <section class="mx-5 my-5 grid grid-cols-4 border bg-gray-100">
      <div class="">
        <img class="object-scale-down h-32 m-0" src={WrkPic} alt="Oculus" />
      </div>
      <div class="col-span-3 ml-4 my-2">
        <h2 class="font-bold">
            QA integration Engineer (Infostar Contractor) - Oculus
        </h2>
        <p class=" my-2 text-sm">Feb 2015 to Nov 2016</p>
        <ul class="list-disc leading-none ml-3 mt-2 pl-6 text-sm">
          <li>
            Develop test plans for Unity and UE4 Oculus Integration
          </li>
          <li>Execute created test plans</li>
          <li>Work with Oculus Unity and UE4 internal developers</li>
        </ul>
      </div>
    </section>
  )
}

export default ResumeWork
