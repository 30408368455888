import React from "react"

import "../../styles/modules/resume/resume.module.scss"

var WrkPic = require("../images/work/Lucasarts_logo.png")

const ResumeWork = () => {
  return (
    <section class="mx-5 my-5 grid grid-cols-4 border bg-gray-100">
      <div class="">
        <img class="object-scale-down h-32 m-0" src={WrkPic} alt="Lucasarts" />
      </div>
      <div class="col-span-3 ml-4 my-2">
        <h2 class="font-bold">
            QA Tester - Lucasarts
        </h2>
        <p class=" my-2 text-sm">Mar 2007 - Sep 2007</p>
        <ul class="list-disc leading-none ml-3 mt-2 pl-6 text-sm">
          <li>
            Performed quality assurance on builds of Thrillville 07, if bugs were found enter them into Devtrak
          </li>
          <li>Platforms worked on Wii, Xbox360, PS2, PSP, PC and Nintendo DS</li>
          <li>Regressed bugs found by third party QA sub-contractors located in Canada</li>
        </ul>
      </div>
    </section>
  )
}

export default ResumeWork